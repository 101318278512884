import React, { Fragment } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ImageDragAndDrop from '../ImageDragAndDrop/ImageDragAndDrop';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';

interface ISortableImageDragAndDropProps {
	id: string;
	image?: File | Blob | null;
	handleFile: (file: File) => void;
	isSelected: boolean;
	isPlaceholder?: boolean;
	handleDelete: (index: number) => void;
}

const SortableImageDragAndDrop = ({
	id,
	image,
	handleFile,
	isSelected = false,
	isPlaceholder = false,
	handleDelete,
}: ISortableImageDragAndDropProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });
	const style = {
		transform: CSS.Transform.toString(transform),
		transition: transition || 'transform 200ms ease',
	};

	const isDraggable = useFeature(FeatureFlags.EnableReorder);

	return (
		<div className="tw-h-full">
			<div
				ref={setNodeRef}
				style={style}
				className={`tw-relative ${
					image ? 'tw-block' : 'tw-flex tw-items-center tw-justify-center'
				}  tw-relative tw-h-full ${
					isSelected && 'tw-border-slate-600'
				} tw-h-full tw-w-full tw-min-h-[52px] md:tw-min-h-[60px] `}
			>
				{isSelected && isDraggable && (
					<i className="ri-drag-move-2-fill tw-z-10 tw-absolute tw-right-px tw-top-0 tw-transform -tw-translate-y-1/2 tw-bg-white tw-text-slate-600 tw-text-lg" />
				)}
				{isPlaceholder ? (
					<div className="tw-w-full tw-h-full">
						<div
							key={`placeholder-item-${id}`}
							className="tw-w-full tw-h-full tw-rounded-lg tw-border tw-min-h-[52px] md:tw-min-h-[60px] "
						/>
					</div>
				) : (
					<Fragment>
						<div
							className={`tw-absolute tw-inset-0 ${
								isDraggable ? 'tw-cursor-move' : ''
							} tw-rounded-lg tw-border tw-p-2 tw-relative tw-h-full tw-w-full ${
								isSelected && 'tw-border-slate-600'
							}`}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...(isDraggable ? attributes : {})}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...(isDraggable ? listeners : {})}
						>
							<ImageDragAndDrop
								image={image}
								title={'Add Photo'}
								subtitle={'or Drag and Drop'}
								iconClass="tw-text-lg tw-font-normal"
								isThumbnail={true}
								handleFile={handleFile}
								handleDelete={handleDelete}
								imageIndex={Number(id)}
							/>
						</div>
					</Fragment>
				)}
			</div>
		</div>
	);
};

SortableImageDragAndDrop.displayName = 'SortableImageDragAndDrop';

export default SortableImageDragAndDrop;
