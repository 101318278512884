import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { TRecentProject } from '../types/RecentProject';
import { Item } from '../types/Item';

const api = new ApiService();
const MAX_IMAGES = 3;

const useGetRecentProjects = (
	initialFilter = '',
	includeItemImages = false
) => {
	const [allProjects, setAllProjects] = useState<TRecentProject[]>([]);
	const [filter, setFilter] = useState(initialFilter);

	const fetchProjects = async (filter: string) => {
		return await api.getRecentProjects(filter);
	};

	const { refetch, isFetching, data, isLoading } = useQuery<TRecentProject[]>(
		['projects', filter],
		() => fetchProjects(filter),
		{
			refetchOnWindowFocus: false,
			onSuccess: (newData) => {
				setAllProjects(newData);
			},
		}
	);

	const fetchImageUrl = async (primaryImageId: string): Promise<string> => {
		const response = await api.getFile(primaryImageId);
		return URL.createObjectURL(response as Blob);
	};

	useEffect(() => {
		const fetchPrimaryImages = async () => {
			if (data && includeItemImages) {
				const updatedProjects = [];

				for (const project of data) {
					const projectId = project.id;

					const itemsResponse = await api.getProjectItems(
						`?$filter=proj eq '${project.proj}'`
					);

					const primaryImages = await Promise.all(
						itemsResponse
							?.filter((item: Item) => item.primaryImageId)
							?.slice(0, MAX_IMAGES)
							.map((item: Item) => fetchImageUrl(item.primaryImageId))
					);
					updatedProjects.push({
						...project,
						primaryImages: primaryImages as [],
						projectId,
					});
				}
				setAllProjects(updatedProjects);
			} else if (data) {
				setAllProjects(data);
			}
		};

		fetchPrimaryImages();
	}, [data, includeItemImages]);

	const updateFilter = (newFilter: string) => {
		setFilter(newFilter);
		refetch();
	};

	return { projects: allProjects, isFetching, updateFilter, isLoading };
};

export default useGetRecentProjects;
