import React from 'react';
import {
	DndContext,
	closestCenter,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
	DragEndEvent,
	DragStartEvent,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableImageDragAndDrop from './SortableImageDragAndDrop';
import { useFormContext } from 'react-hook-form';
import { TGalleryImages, TItemImage } from 'legacy/lib/api/types/ItemImages';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';

interface IThumbnailGalleryProps {
	setSelectedImage: (value: TGalleryImages) => void;
	selectedImageIndex: number;
	galleryImages: TItemImage[];
}

const MAX_IMAGES = 4;

const ThumbnailGallery = ({
	setSelectedImage,
	selectedImageIndex,
	galleryImages = [],
}: IThumbnailGalleryProps) => {
	const methods = useFormContext();
	const { setValue } = methods;
	const isDraggable = useFeature(FeatureFlags.EnableReorder);

	const filledImages = [
		...(galleryImages || []),
		...Array(MAX_IMAGES - (galleryImages?.length || 0)).fill(null),
	].slice(0, MAX_IMAGES);

	const firstNullIndex = filledImages?.findIndex((image) => image === null);
	const placeholderStartIndex = firstNullIndex <= 2 ? firstNullIndex + 1 : null;

	const sensors = useSensors(
		useSensor(MouseSensor),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 5,
			},
		})
	);

	const handleDragEnd = (event: DragEndEvent) => {
		if (!isDraggable) return;
		const { active, over } = event;

		if (!active || !over) return;

		if (active.id !== over.id) {
			const oldIndex = galleryImages?.findIndex(
				(item: TItemImage, idx: number) => idx.toString() === active.id
			);
			const newIndex = galleryImages?.findIndex(
				(item: TItemImage, idx: number) => idx.toString() === over.id
			);

			const newImages = arrayMove(galleryImages, oldIndex, newIndex);

			setSelectedImage({
				file: newImages[newIndex]?.file,
				index: newIndex,
			});
			setValue('images', newImages);
		}
	};

	const handleImageAdd = (newImage: File, index: number) => {
		const updatedImages = [...galleryImages];
		updatedImages[index] = { file: newImage, fileId: null };

		const convertedImages = updatedImages?.map((image) => ({
			file: image?.file,
			fileId: image?.fileId ?? null,
		}));

		setValue('images', convertedImages);
		setSelectedImage({ file: newImage, index });
	};

	const handleDragStart = (event: DragStartEvent) => {
		if (!isDraggable) return;
		const { active } = event;
		const draggedIndex = Number(active.id);
		setSelectedImage({
			file: filledImages[draggedIndex]?.file,
			index: Number(draggedIndex),
		});
	};

	const handleDelete = (index: number) => {
		if (galleryImages?.length > 0) {
			galleryImages?.splice(index, 1);
		} else {
			setValue('images', []);
		}
		const selectedImageFile = galleryImages?.[index]?.file;
		setSelectedImage({ file: selectedImageFile, index: index });
	};

	const handleClick = (index: number) => {
		if (!isDraggable) {
			const selectedImageFile = filledImages[index]?.file;
			setSelectedImage({ file: selectedImageFile, index });
		}
	};

	return (
		<div className="tw-mt-5 tw-min-h-24 md:tw-content-end">
			{isDraggable ? (
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
					onDragStart={handleDragStart}
				>
					<SortableContext
						items={filledImages?.map((_, index) => index.toString())}
						strategy={horizontalListSortingStrategy}
					>
						<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-grid-rows-1 tw-gap-4 lg:tw-gap-8">
							{filledImages?.map((image, index) => {
								const itemKey = image?.fileId || index;
								return (
									<div
										className={`tw-w-full tw-h-full tw-min-h-[90px] md:tw-min-h-0`}
										key={`image-sort-tile-${itemKey}`}
										onClick={() => handleClick(index)}
									>
										<SortableImageDragAndDrop
											id={index.toString()}
											image={image?.file}
											handleFile={(file: File) => handleImageAdd(file, index)}
											isSelected={selectedImageIndex === index}
											isPlaceholder={
												placeholderStartIndex
													? placeholderStartIndex <= index
													: false
											}
											handleDelete={handleDelete}
										/>
									</div>
								);
							})}
						</div>
					</SortableContext>
				</DndContext>
			) : (
				<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-grid-rows-1 tw-gap-4 lg:tw-gap-8">
					{filledImages?.map((image, index) => {
						const itemKey = image?.fileId || index;
						return (
							<div
								className={`tw-w-full tw-h-full tw-min-h-[90px] md:tw-min-h-0 tw-cursor-pointer`}
								key={`image-sort-tile-${itemKey}`}
								onClick={() => handleClick(index)}
							>
								<SortableImageDragAndDrop
									id={index.toString()}
									image={image?.file}
									handleFile={(file: File) => handleImageAdd(file, index)}
									isSelected={selectedImageIndex === index}
									isPlaceholder={
										placeholderStartIndex
											? placeholderStartIndex <= index
											: false
									}
									handleDelete={handleDelete}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

ThumbnailGallery.displayName = 'ThumbnailGallery';

export default ThumbnailGallery;
