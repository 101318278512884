import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'legacy/app/components/help/Spinner';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import { FormProvider, useForm } from 'react-hook-form';
import {
	WhatChanged,
	WhatChangedPayload,
} from 'legacy/lib/api/types/WhatChangedItems';
import useRecalculate from 'legacy/lib/api/hooks/useRecalculate';
import useEditItem from 'legacy/lib/api/hooks/useEditItem';
import URI from 'legacy/defaults/RoutesDefault';
import useUploadFile from 'legacy/lib/api/hooks/useUploadFile';
import getItemOrMainComponentDescription from 'legacy/utilities/getItemOrMainComponentDescription';
import isValueANumber from 'legacy/utilities/isValueANumber';
import { AxiosError } from 'axios';
import { UserError } from 'legacy/lib/api/types/UserError';
import MSG from 'legacy/defaults/Message';
import useCreateComponent from 'legacy/lib/api/hooks/useCreateComponent';
import useGetItem from 'legacy/lib/api/hooks/useGetItem';
import { ItemFormValues } from './types/FormValues';
import useDeleteFile from 'legacy/lib/api/hooks/useDeleteFile';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { useFeature } from 'use-feature';
import getPreloadValues from './utils/getPreloadValues';
import useGetComponents from 'legacy/lib/api/hooks/useGetComponents';
import useGetItemImages from 'legacy/lib/api/hooks/useGetItemImages';
import useUpdateImageOrder from 'legacy/lib/api/hooks/useUpdateImageOrder';
import { TItemOrder } from 'legacy/lib/api/types/ItemImages';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import ItemTabs, { TTabOption } from './components/Tabs';
import Info from './components/Info';
import LegacyItemTabsComponents from 'legacy/templates/modules/item/item-add-for-refactored-item-page/ItemAdd';
import { queryClient } from 'api/queryClient';
import WorkRoomTab from './components/WorkroomTab';
import PricingTab from './components/PricingTab';
import StatusTab from './components/StatusTab';

// Need to do a require because this module doesn´t have any types
/* eslint-disable @typescript-eslint/no-var-requires */
const HtmlToRtfBrowser = require('html-to-rtf-browser').default;

const ItemsPage = () => {
	const { id: projectId, itemId } = useParams();
	const [activeTab, setActiveTab] = useState('info');
	const [updatedData, setUpdatedData] = useState<{ [key: string]: any }>({});
	const [description, setDescription] = useState('');
	const filter = `?$filter=ObjectIdStr eq '${itemId}' and objectType eq 'Item' &$orderby=order`;
	const {
		data: project,
		isLoading: isProjectLoading,
		error: projectFetchError,
	} = useGetProject(projectId as string);

	const { mutateAsync: deleteFile } = useDeleteFile();
	const { mutate: orderImage } = useUpdateImageOrder();

	const {
		data: itemToEdit,
		isFetching: isLoadingItem,
		error: itemLoadingError,
	} = useGetItem(itemId as string);

	const { data: initialImagesData } = useGetItemImages(filter);

	const showImageGallery = useFeature(FeatureFlags.MultipleImages);
	const enableReorder = useFeature(FeatureFlags.EnableReorder);

	const navigate = useNavigate();

	const { data: components } = useGetComponents(
		itemId as string,
		projectId as string
	);

	const { mutateAsync: createComponents, isLoading: isCreatingComponents } =
		useCreateComponent(project?.id as number);

	const { mutateAsync: editItem, isLoading: isEditing } = useEditItem();

	const { mutateAsync: uploadImage } = useUploadFile();

	const [whatChanged, setWhatChanged] = useState<WhatChangedPayload | object>(
		{}
	);

	const [numberOfDocuments, setNumberOfDocuments] = useState(0);

	const redirectToComponents = useRef(false);

	const customRedirect = useRef('');

	const { data: recalculatedData, isFetching: isRecalculating } =
		useRecalculate(itemToEdit?.id as number, JSON.stringify(whatChanged), {
			enabled:
				itemToEdit?.id !== undefined && Object.values(whatChanged).length > 0,
		});

	const methods = useForm<ItemFormValues>({
		defaultValues: {
			quantity: '0',
			cldeppct: '0',
			description: {
				html: '',
				value: '',
			},
		},
	});
	const { setValue } = methods;

	useEffect(() => {
		const preloadValues = async () => {
			if (itemToEdit) {
				const preloadValues = await getPreloadValues(itemToEdit);
				setDescription(preloadValues.description.html);
				const entries = Object.entries(preloadValues);
				entries.forEach(([name, value], index) => {
					setValue(name as keyof ItemFormValues, value);
					if (index === entries.length - 1) {
						setWhatChanged({
							quantity: itemToEdit.quantity,
							clDepPct: itemToEdit.cldeppct,
							budget: itemToEdit.budget,
							unitBudget: preloadValues.unitbudget,
							loc: itemToEdit.loc,
							scat: itemToEdit.scat,
							budgetCheckOriginalEstPrices: {
								estMPrice: itemToEdit.estmprice as number,
								estDPrice: itemToEdit.estdprice as number,
								estFPrice: itemToEdit.estfprice as number,
								estIPrice: itemToEdit.estiprice as number,
								estLPrice: itemToEdit.estlprice as number,
								estOPrice: itemToEdit.estoprice as number,
								estTPrice: itemToEdit.esttprice as number,
							},
							whatChanged: WhatChanged.RECALC_FROM_COMPS_AND_STATUS,
						});
					}
				});
			}
		};
		preloadValues();
	}, [setValue, itemToEdit]);

	useEffect(() => {
		if (recalculatedData) {
			const {
				budget,
				unitBudget,
				clDepPct,
				quantity,
				estDep,
				estTPrice,
				estTCost,
			} = recalculatedData;

			const newValues = {
				budget: String(budget),
				unitbudget: String(unitBudget),
				esttcost: String(estTCost),
				esttprice: String(estTPrice),
				estdep: String(estDep),
				cldeppct: String(clDepPct),
				quantity: String(quantity),
			};

			Object.entries(newValues).forEach(([name, value]) =>
				setValue(name as keyof ItemFormValues, value)
			);
		}
	}, [recalculatedData, setValue]);

	const handleRecalculate = (whatPropertyChanged: `${WhatChanged}`) => {
		const formValues = methods.getValues();
		const newWhatChanged: WhatChangedPayload = {
			whatChanged: whatPropertyChanged,
			quantity: formValues.quantity || 0,
			clDepPct: formValues.cldeppct || 0,
			budget: formValues.budget || 0,
			unitBudget: formValues.unitbudget || 0,
			loc: (formValues.loc?.value as string) || null,
			scat: (formValues.scat?.value as string) || null,
			budgetCheckOriginalEstPrices: {
				estMPrice: itemToEdit?.estmprice as number,
				estDPrice: itemToEdit?.estdprice as number,
				estFPrice: itemToEdit?.estfprice as number,
				estIPrice: itemToEdit?.estiprice as number,
				estLPrice: itemToEdit?.estlprice as number,
				estOPrice: itemToEdit?.estoprice as number,
				estTPrice: itemToEdit?.esttprice as number,
			},
		};

		// The query will refetch whenever whatChanged changes. But if there hasn´t been any change, we trigger a manual refetch
		if (JSON.stringify(whatChanged) === JSON.stringify(newWhatChanged)) {
			queryClient.resetQueries([itemId, 'recalculate', newWhatChanged]);
		}
		setWhatChanged(newWhatChanged);
	};

	const handleImage = async () => {
		const image = methods.watch('image');

		if (itemToEdit?.primaryImageId) {
			if (itemToEdit?.primaryImageId && !image) {
				await deleteFile(itemToEdit?.primaryImageId);
				return '';
			} else if (itemToEdit?.primaryImageId && image) {
				await deleteFile(itemToEdit?.primaryImageId);
				const newImage = await uploadImage({
					file: image,
					ObjectType: 'item',
					ObjectId: itemToEdit?.id as number,
					FileType: 1,
					fileContext: 0,
				});
				return newImage?.id;
			}
		}
		if (image) {
			const uploadedImage = await uploadImage({
				file: image,
				ObjectType: 'item',
				ObjectId: itemToEdit?.id as number,
				FileType: 1,
				fileContext: 0,
			});
			return uploadedImage.id;
		}
	};

	const handleImages = async () => {
		const images = methods.watch('images');

		if (!images) {
			return '';
		}

		let primaryImageId = images?.[0]?.fileId || '';

		for (let i = 0; i < images.length; i++) {
			const image = images[i];
			const initialImage = initialImagesData?.find(
				(img) => img.fileId === image.fileId
			);
			if (!initialImage && image.fileId === null) {
				const uploadedImage = await uploadImage({
					file: image.file,
					ObjectType: 'item',
					ObjectId: itemToEdit?.id as number,
					FileType: 1,
					fileContext: 0,
				});
				if (i === 0) {
					primaryImageId = uploadedImage.id;
				}

				image.fileId = uploadedImage.id;
			}
		}

		for (let i = 0; i < (initialImagesData?.length || 0); i++) {
			const initialImage = initialImagesData?.[i];
			const currentImage = images.find(
				(img) => img.fileId === initialImage?.fileId
			);
			if (!currentImage && initialImage?.fileId) {
				await deleteFile(initialImage.fileId);
			}
		}
		if (enableReorder) {
			for (let i = 0; i < (images?.length || 0); i++) {
				const image = images[i];
				const initialImage = initialImagesData?.find(
					(img) => img.fileId === image.fileId
				);
				if (
					initialImage &&
					initialImagesData?.indexOf(initialImage) !== i &&
					image.fileId !== null
				) {
					const imageOrder = {
						fileId: image.fileId,
						newOrder: i,
					};
					handleUpdateOrder(imageOrder);
				}
			}
		}

		return primaryImageId;
	};

	const onSubmit = async (data: ItemFormValues) => {
		displayAlertLoader(MSG.loading.update.msg);
		try {
			const itemDescription = await getItemOrMainComponentDescription(
				data.itemName,
				data.description
			);

			const uploadedPrimaryImage = showImageGallery
				? await handleImages()
				: await handleImage();

			delete updatedData.defaultLocation;
			delete updatedData.defaultSalesCategory;

			if (updatedData.selectedShipto) {
				updatedData.wrshipto = updatedData.selectedShipto.value;
				delete updatedData.selectedShipto;
			}
			await editItem({
				...itemDescription,
				itemName: data.itemName,
				budgethrs: data.budgethrs,
				wrshipto: data.wrshipto || null,
				id: itemToEdit?.id as number,
				primaryImageId: uploadedPrimaryImage || '',
				budget: isValueANumber(data.budget),
				unitmeasure: data.unitmeasure?.label as string,
				estdep: isValueANumber(data.estdep),
				workroom: data.workroom || null,
				wrdesc: data.workroomDescription.value,
				wrdescrtf: new HtmlToRtfBrowser().convertHtmlToRtf(
					data.workroomDescription.html || ''
				),
				esttcost: isValueANumber(data.esttcost),
				esttprice: isValueANumber(data.esttprice),
				quantity: isValueANumber(data.quantity),
				scat: data.scat?.value as string,
				loc: data.loc?.value as string,
				cldeppct: isValueANumber(data.cldeppct),
				...updatedData,
			});

			const createAutoComponents =
				project?.autom ||
				project?.autod ||
				project?.autof ||
				project?.autoi ||
				project?.autol ||
				project?.autoo ||
				false;

			const createComponentPayload = {
				itemId: itemToEdit?.id as number,
				componentType: 0,
				associatedComponentNumber: '001',
				createAutoComponents,
				setDefaultsFromItem: true,
			};

			if (!redirectToComponents.current && !components?.length) {
				await createComponents({
					unitMeasure: (data.unitmeasure?.label as string) || '',
					createComponentPayload,
					createAutoComponents,
				});
			}

			if (redirectToComponents.current) {
				const newComponent = await createComponents({
					createComponentPayload: {
						...createComponentPayload,
						associatedComponentNumber: '',
					},
					unitMeasure: (data.unitmeasure?.label as string) || '',
					createAutoComponents,
				});
				hideAlertLoader();
				return navigate(
					generatePath(`${URI.project.newComponentEdit}?isFromAdd=true`, {
						id: projectId,
						itemId: itemToEdit?.id as number,
						componentId: newComponent?.id,
					})
				);
			}
			hideAlertLoader();
			displayAlertSuccess(`Item edited successfully!`);
			navigate(
				customRedirect.current ||
					generatePath(URI.project.projectItems, {
						id: projectId,
					})
			);
		} catch (error) {
			customRedirect.current = '';
			redirectToComponents.current = false;
			hideAlertLoader();
			displayAlertError(
				(error as AxiosError<UserError>)?.response?.data?.userError ||
					'There was an error saving the information, please try again'
			);
		}
	};

	const handleErrors = () => displayAlertError('Please enter a Sales Category');

	const disableButtons = isRecalculating || isEditing || isCreatingComponents;

	const values = methods.getValues();

	const submitMethods = methods.handleSubmit(onSubmit, handleErrors);

	const submitFormFromOutside = useCallback(
		async (customRedirectPath?: string) => {
			if (!customRedirectPath) {
				redirectToComponents.current = true;
			} else {
				customRedirect.current = customRedirectPath;
			}
			await submitMethods();
		},
		[submitMethods]
	);

	const handleUpdateOrder = (imageOrder: TItemOrder) => {
		orderImage(imageOrder, {
			onError: (error) => {
				throw new Error('Failed to update image order:', error);
			},
		});
	};

	if (isProjectLoading || isLoadingItem) {
		return <Spinner />;
	}

	if (projectFetchError || itemLoadingError) {
		return <></>;
	}

	return (
		<FormProvider
			resetField={methods.resetField}
			getFieldState={methods.getFieldState}
			handleSubmit={methods.handleSubmit}
			watch={methods.watch}
			setError={methods.setError}
			getValues={methods.getValues}
			reset={methods.reset}
			setValue={methods.setValue}
			clearErrors={methods.clearErrors}
			control={methods.control}
			register={methods.register}
			unregister={methods.unregister}
			setFocus={methods.setFocus}
			trigger={methods.trigger}
			formState={methods.formState}
		>
			<form onSubmit={submitMethods}>
				<Header
					disableSaveButton={!!project?.closeddt}
					itemName={itemToEdit?.itemName as string}
					budgetCheck={recalculatedData?.budgetCheck}
					isEdit={typeof values.itemName === 'string'}
					itemId={itemToEdit?.id as number}
					disableButtons={disableButtons}
				/>
				<p className="tw-text-base bg-ivory tw-m-0 tw-pl-10">
					Ref. No. {itemToEdit?.item}
				</p>
				<ItemTabs
					numDocuments={numberOfDocuments}
					activeTab={activeTab as TTabOption}
					toggleTab={(newTab) => setActiveTab(newTab)}
				/>
				{activeTab === 'info' && (
					<Info
						disableAddComponentButton={
							recalculatedData?.budgetCheck.passed === false ||
							!!project?.closeddt
						}
						projectCode={project?.proj as string}
						itemToEdit={itemToEdit}
						description={description}
						submitFormFromOutside={submitFormFromOutside}
						disableButtons={disableButtons}
						handleRecalculate={handleRecalculate}
					/>
				)}
				{activeTab === 'status' && itemToEdit && (
					<StatusTab itemToEdit={itemToEdit} />
				)}
				{activeTab === 'workroom' && itemToEdit && (
					<WorkRoomTab disableInputs={disableButtons} itemToEdit={itemToEdit} />
				)}
				{activeTab === 'pricing' && itemToEdit && components && (
					<PricingTab itemToEdit={itemToEdit} components={components} />
				)}
			</form>
			<LegacyItemTabsComponents
				project={project}
				item={itemToEdit}
				activeMenu={activeTab}
				onChildrenDataChange={setUpdatedData}
				setNumberOfDocuments={setNumberOfDocuments}
			/>
			<div className="tw-p-8 bg-ivory gradient light">
				<SecureBootstrapButton
					onClick={submitMethods}
					disabled={
						disableButtons ||
						recalculatedData?.budgetCheck.passed === false ||
						!!project?.closeddt
					}
					data-testid="items-page-save-button"
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
					variant="primary"
				>
					Save
				</SecureBootstrapButton>
			</div>
		</FormProvider>
	);
};

ItemsPage.displayName = 'ItemsPage';

export default ItemsPage;
