import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import UiKitLayout from '../templates/layout/UiKitLayout';
import LoginLayout from '../templates/layout/LoginLayout';
import Project from '../templates/modules/project/Project';
import ProjectAdd from '../templates/modules/project/ProjectAdd';
import ProjectAddBudgetAdd from '../templates/modules/project/ProjectAddBudgetAdd';
import ProjectAddMarkup from '../templates/modules/project/ProjectAddMarkup';
import ProjectAddItemsStore from '../templates/modules/project/ProjectAddItemsStore';
import ProjectView from '../templates/modules/project/ProjectView';
import ProjectProposalHistory from '../templates/modules/project/proposal/ProjectProposalHistory';
import ProjectList from '../templates/modules/project/ProjectList';
import URI from '../defaults/RoutesDefault';
import Base from '../templates/Base';
import TaskAppointment from '../templates/modules/task-appointment/TaskAppointment';
import TimeManagement from '../templates/modules/task-appointment/TimeManagement';
import TaskAppointmentAdd from '../templates/modules/task-appointment/TaskAppointmentAdd';
import TaskAppointmentToDoAdd from '../templates/modules/task-appointment/TaskAppointmentToDoAdd';
import ReviewSend from '../templates/modules/review-send/ReviewSend';
import ReviewSendList from '../templates/modules/review-send/ReviewSendList';
import Glossary from '../templates/modules/glossary/Glossary';
import GlossaryList from '../templates/modules/glossary/GlossaryList';
import Item from '../templates/modules/item/Item';
import StockItems from '../templates/modules/stock-items/StockItems';
import StockItemsList from '../templates/modules/stock-items/list/StockItemsList';
import StockItemEdit from '../templates/modules/stock-items/edit/StockItemEdit';
import ItemSearch from '../templates/modules/item/item-search/ItemSearch';
import ItemSearchList from '../templates/modules/item/item-search/ItemSearchList';
import Clipper from '../templates/modules/item/clipper/Clipper';
import ClipperGrid from '../templates/modules/item/clipper/ClipperGrid';
import Catalog from '../templates/modules/item/catalog/Catalog';
import CatalogItem from '../templates/modules/item/catalog/CatalogItem';
import CatalogItemEdit from '../templates/modules/item/catalog/CatalogItemEdit';
import CatalogList from '../templates/modules/item/catalog/CatalogList';
import EmployeesAdd from '../templates/modules/employee/EmployeesAdd';
import Reporting from '../templates/modules/report/Reporting';
import UiKitHome from '../templates/modules/uikit/UiKitHome';
import UiKitComponents from '../templates/modules/uikit/UiKitComponents';
import Typography from '../templates/modules/uikit/Typography';
import Icons from '../templates/modules/uikit/Icons';
import Colours from '../templates/modules/uikit/Colours';
import Contacts from '../templates/modules/Contacts';
import AccountsReceivable from '../templates/modules/accounts-receivable/AccountsReceivable';
import ClientInvoices from '../templates/modules/accounts-receivable/client-invoices/ClientInvoices';
import ClientInvoicesList from '../templates/modules/accounts-receivable/client-invoices/ClientInvoicesList';
import ClientInvoiceAdd from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceAdd';
import ClientInvoiceFinanceCharge from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceFinanceCharge';
import CashReceiptList from '../templates/modules/accounts-receivable/cash-receipt/CashReceiptList';
import CashReceiptAdd from '../templates/modules/accounts-receivable/cash-receipt/CashReceiptAdd';
import CashReceipt from '../templates/modules/accounts-receivable/cash-receipt/CashReceipt';
import CashReceiptAddMisc from '../templates/modules/accounts-receivable/cash-receipt/CashReceiptAddMisc';
import CashReceiptExistingView from '../templates/modules/accounts-receivable/cash-receipt/CashReceiptExistingView';
import AccountsPayable from '../templates/modules/accounts-payable/AccountsPayable';
import VendorInvoiceList from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoiceList';
import VendorInvoice from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoice';
import VendorInvoiceAdd from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoiceAdd';
import VendorInvoiceAddDeposit from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoiceAddDeposit';
import VendorDepositAdd from '../templates/modules/accounts-payable/vendor-invoice/VendorDepositAdd';
import VendorMiscAdd from '../templates/modules/accounts-payable/vendor-invoice/VendorMiscAdd';
import VendorInvoiceAddRecurring from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoiceAddRecurring';
import VendorInvoiceEditdExpense from '../templates/modules/accounts-payable/vendor-invoice/VendorInvoiceEditdExpense';
import GeneralLedger from '../templates/modules/general-ledger/GeneralLedger';
import Account from '../templates/modules/general-ledger/account/Account';
import AccountList from '../templates/modules/general-ledger/account/AccountList';
import AccountAdd from '../templates/modules/general-ledger/account/AccountAdd';
import ReturnsAndCredits from '../templates/modules/accounts-receivable/returns-and-credit/ReturnsAndCredits';
import ReturnsAndCreditsList from '../templates/modules/accounts-receivable/returns-and-credit/ReturnsAndCreditsList';
import Payment from '../templates/modules/accounts-payable/payment/Payment';
import PaymentList from '../templates/modules/accounts-payable/payment/PaymentList';
import Checkbook from '../templates/modules/accounts-payable/checkbook/Checkbook';
import CheckbookList from '../templates/modules/accounts-payable/checkbook/CheckbookList';
import Reconcile from '../templates/modules/accounts-payable/checkbook/Reconcile';
import CheckbookJournalEntryDetail from '../templates/modules/accounts-payable/checkbook/CheckbookJournalEntryDetail';
import CheckbookCheckDetail from '../templates/modules/accounts-payable/checkbook/CheckbookCheckDetail';
import CheckbookDepositDetail from '../templates/modules/accounts-payable/checkbook/CheckbookDepositDetail';
import CreditCard from '../templates/modules/accounts-payable/credit-card/CreditCard';
import CreditCardList from '../templates/modules/accounts-payable/credit-card/CreditCardList';
import { CreditCardReconcile } from '../pages/accounts-payable/credit-card/reconcile';
import { CreditCardReconcileHistory } from '../pages/accounts-payable/credit-card/reconcile/history';
import VendorCredit from '../templates/modules/accounts-payable/vendor-credit/VendorCredit';
import VendorCreditList from '../templates/modules/accounts-payable/vendor-credit/VendorCreditList';
import JournalEntry from '../templates/modules/general-ledger/journal-entry/JournalEntry';
import JournalEntryList from '../templates/modules/general-ledger/journal-entry/JournalEntryList';
import JournalEntryAdd from '../templates/modules/general-ledger/journal-entry/JournalEntryAdd';
import TransactionSearch from '../templates/modules/general-ledger/transaction-search/TransactionSearch';
import TransactionSearchList from '../templates/modules/general-ledger/transaction-search/TransactionSearchList';
import Settings from '../templates/modules/settings/Settings';
import { SettingsList } from '../templates/modules/settings/SettingsList';
import PaymentSettings from '../templates/modules/settings/payment-settings/PaymentSettings';
import PaymentListSettings from '../templates/modules/settings/payment-settings/PaymentListSettings';
import User from '../templates/modules/settings/user-management/User';
import UserList from '../templates/modules/settings/user-management/UserList';
import UserAdd from '../templates/modules/settings/user-management/UserAdd';
import UserEdit from '../templates/modules/settings/user-management/UserEdit';
import UserSettings from '../templates/modules/settings/user-settings/UserSettings';
import UserSettingsList from '../templates/modules/settings/user-settings/UserSettingsList';
import ItemAdd from '../templates/modules/item/item-add/ItemAdd';
import ItemAddComponentAdd from '../templates/modules/item/item-add/ItemAddComponentAdd';
import GlossaryAddLocations from '../templates/modules/glossary/GlossaryAddLocations';
import GlossaryAddSalesCategory from '../templates/modules/glossary/GlossaryAddSalesCategory';
import GlossaryAddItemGroups from '../templates/modules/glossary/GlossaryAddItemGroups';
import GlossaryAddShipToCodes from '../templates/modules/glossary/GlossaryAddShipToCodes';
import GlossaryAddRemarks from '../templates/modules/glossary/GlossaryAddRemarks';
import GlossaryAddSpecificationTemplates from '../templates/modules/glossary/GlossaryAddSpecificationTemplates';
import GlossaryAddTimeActivities from '../templates/modules/glossary/GlossaryAddTimeActivities';
import GlossaryAddSalesTaxCodes from '../templates/modules/glossary/GlossaryAddSalesTaxCodes';
import GlossaryAddSalesTaxCalculation from '../templates/modules/glossary/GlossaryAddSalesTaxCalculation';
import GlossaryEditSalesTaxCalculation from '../templates/modules/glossary/GlossaryEditSalesTaxCalculation';
import GlossaryAddUnitsOfMeasure from '../templates/modules/glossary/GlossaryAddUnitsOfMeasure';
import ClientInvoiceAddOverridePrice from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceAddOverridePrice';
import ClientInvoiceExistingView from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceExistingView';
import ClientInvoiceExistingInvoiceAdjust from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceExistingInvoiceAdjust';
import ClientInvoiceExistingInvoiceAdjustAdd from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceExistingInvoiceAdjustAdd';
import ClientInvoiceExistingReverseCreditInvoice from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceExistingReverseCreditInvoice';
import ClientInvoiceExistingUpdatedHighlightedInvoice from '../templates/modules/accounts-receivable/client-invoices/ClientInvoiceExistingUpdatedHighlightedInvoice';
import PaymentFormModifyAmount from '../templates/modules/accounts-payable/payment/PaymentFormModifyAmount';
import PaymentFormCreditCardPayment from '../templates/modules/accounts-payable/payment/PaymentFormCreditCardPayment';
import PaymentFormOnlinePayment from '../templates/modules/accounts-payable/payment/PaymentFormOnlinePayment';
import PaymentFormCheckWirePayment from '../templates/modules/accounts-payable/payment/PaymentFormCheckWirePayment';
import JournalEntryRecurring from '../templates/modules/general-ledger/journal-entry/JournalEntryRecurring';
import JournalEntryRecurringAdd from '../templates/modules/general-ledger/journal-entry/JournalEntryRecurringAdd';
import ProjectProposalAdd from '../templates/modules/project/proposal/ProjectProposalAdd';
import ProjectVendorOrdersAddPurchaseOrder from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddPurchaseOrder';
import ProjectVendorOrdersModifyStatus from '../templates/modules/project/vendor-orders/ProjectVendorOrdersModifyStatus';
import ProjectVendorOrdersModifyStatusEdit from '../templates/modules/project/vendor-orders/ProjectVendorOrdersModifyStatusEdit';
import ItemAddComponentEditOrderStatusInvoice from '../templates/modules/item/item-add/ItemAddComponentEditOrderStatusInvoice';
import ItemAddComponentEditOrderStatusPO from '../templates/modules/item/item-add/ItemAddComponentEditOrderStatusPO';
import ItemTransfer from '../templates/modules/item/item/ItemTransfer';
import ItemTransferEdit from '../templates/modules/item/item/ItemTransferEdit';
import ProjectVendorOrdersAddWorkOrder from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddWorkOrder';
import ProjectVendorOrdersAddWorkOrderGeneratedWO from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddWorkOrderGeneratedWO';
import ProjectVendorOrdersAddWorkOrderEditGeneratedWO from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddWorkOrderEditGeneratedWO';
import ProjectVendorOrdersAddPurchaseOrderGeneratedPO from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddPurchaseOrderGeneratedPO';
import ProjectVendorOrdersAddPurchaseOrderEditGeneratedPO from '../templates/modules/project/vendor-orders/ProjectVendorOrdersAddPurchaseOrderEditGeneratedPO';
import CatalogItemComponentAdd from '../templates/modules/item/catalog/CatalogItemComponentAdd';
import CatalogItemComponentEditOrderStatusInvoice from '../templates/modules/item/catalog/CatalogItemComponentEditOrderStatusInvoice';
import CatalogItemComponentEditOrderStatusPO from '../templates/modules/item/catalog/CatalogItemComponentEditOrderStatusPO';
import Login from '../templates/modules/login/Login';
import ForgotPassword from '../templates/modules/login/ForgotPassword';
import AppDependencyContainer from './AppDependencyContainer';
import AppLayout from './layouts/AppLayout';
import NotFoundPage from './pages/Home/NotFoundPage';
import TimeEntryListView from './pages/TimeEntry/views/TimeEntryListView';
import TimeEntryAddEditView from './pages/TimeEntry/views/TimeEntryAddEditView';
import ClientListComponent from './pages/Client/ClientListComponent';
import ClientAddEditComponent from './pages/Client/ClientAddEditComponent';
import VendorListComponent from './pages/Vendor/VendorListComponent';
import VendorAddEditComponent from './pages/Vendor/VendorAddEditComponent';
import EmployeeListComponent from './pages/Employee/EmployeeListComponent';
import EmployeeAddEditComponent from './pages/Employee/EmployeeAddEditComponent';
import EmployeeAddEditTimeActivity from './pages/Employee/components/EmployeeAddEditTimeActivity';
import ClientAddEditViewModel from './pages/Client/ClientAddEditViewModel';
import AddressAddEditViewModel from './pages/Address/AddressAddEditViewModel';
import AccountSettings from '../templates/modules/account-settings/AccountSettings';
import WorkflowSettings from '../templates/modules/settings/workflow-settings/WorkflowSettings';
import WorkflowSettingsMain from '../templates/modules/settings/workflow-settings/WorkflowSettingsMain';
import AcceptInvitation from '../templates/modules/accept-invitation/AcceptInvitation';
import ResetPassword from '../templates/modules/reset-password/ResetPassword';
import { isLoggedIn } from '../utilities/Auth';
import { useLocation } from 'react-router-dom';
import JournalEntryEdit from '../templates/modules/general-ledger/journal-entry/JournalEntryEdit';
// Hook Forms
import Company from '../pages/settings/company';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ProjectProposalStatus from '../templates/modules/project/proposal/ProjectProposalStatus';
import ComingSoon from '../templates/modules/accounts-payable/ComingSoon';
import { SECURITY_ATTRIBUTE_TYPES, isErrorInStatus } from './context/security';
import RouteGuard from './components/security/RouteGuard';
import TimeZoneChecker from './components/TimeZoneChecker/TimeZoneChecker';
import TimeZoneContextProvider from './components/TimeZoneContextProvider/TimeZoneContextProvider';
import BudgetBannerProvider from './context/BudgetBannerContext';
import { AxiosError } from 'axios';
import InvalidInstanceComponent from './pages/InvalidInstance/InvalidInstance';
import DesignPay from './pages/DesignPay/DesignPay';
import ItemsPage from './pages/Items/Items';
import Component from './pages/Component/Component';
import Dashboard from './pages/Dashboard/Dashboard';
import { CheckbookHistory } from '../templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistory';
import { ReconcileProvider } from 'legacy/pages/accounts-payable/credit-card/reconcile/ReconcileContext';

interface State {}
interface Props {}

class AppNavigation extends React.Component<Props, State> {
	private appContainer = new AppDependencyContainer();

	render() {
		const queryClient = new QueryClient({
			defaultOptions: {
				queries: {
					retry: (_, error) => {
						return !isErrorInStatus((error as AxiosError)?.response?.status);
					},
				},
			},
		});

		return (
			<QueryClientProvider client={queryClient}>
				<Routes>
					{/* App Layout */}
					<Route
						path="/"
						element={
							<RequireAuth>
								<TimeZoneContextProvider>
									<TimeZoneChecker>
										<AppLayout />
									</TimeZoneChecker>
								</TimeZoneContextProvider>
							</RequireAuth>
						}
					>
						<Route index element={<Navigate to={URI.dashboard.base} />} />

						<Route path={URI.project.base} element={<Project />}>
							<Route path={URI.project.list} element={<ProjectList />} />
							<Route path={URI.project.add} element={<ProjectAdd />} />
							<Route
								path={URI.project.addMarkup}
								element={<ProjectAddMarkup />}
							/>
							<Route
								path={URI.project.editMarkup}
								element={<ProjectAddMarkup />}
							/>
							<Route path={URI.project.view} element={<ProjectView />} />
							<Route path={URI.project.viewPage} element={<ProjectView />} />

							{/* Proposals */}
							<Route
								path={URI.project.proposal.history}
								element={<ProjectProposalHistory />}
							/>
							<Route
								path={URI.project.proposal.add}
								element={<ProjectProposalAdd />}
							/>
							<Route
								path={URI.project.proposal.edit}
								element={<ProjectProposalAdd />}
							/>
							<Route
								path={URI.project.proposal.status}
								element={<ProjectProposalAdd />}
							/>
							<Route
								path={URI.project.proposal.setStatus}
								element={<ProjectProposalStatus />}
							/>
							<Route
								path={URI.project.proposal.send}
								element={<ProjectProposalAdd />}
							/>
							<Route
								path={URI.project.proposal.delete}
								element={<ProjectProposalAdd />}
							/>

							{/* Vendor Orders */}
							<Route
								path={URI.project.vendorOrder.addPurchaseOrder}
								element={<ProjectVendorOrdersAddPurchaseOrder />}
							/>
							<Route
								path={URI.project.vendorOrder.editPurchaseOrder}
								element={<ProjectVendorOrdersAddPurchaseOrder />}
							/>
							<Route
								path={URI.project.vendorOrder.addPurchaseOrderGeneratedPO}
								element={<ProjectVendorOrdersAddPurchaseOrderGeneratedPO />}
							/>
							<Route
								path={URI.project.vendorOrder.addPurchaseOrderEditGeneratedPO}
								element={<ProjectVendorOrdersAddPurchaseOrderEditGeneratedPO />}
							/>
							<Route
								path={URI.project.vendorOrder.pOcomponentEdit}
								element={<ItemAddComponentAdd />}
							/>
							<Route
								path={URI.project.vendorOrder.addWorkOrder}
								element={<ProjectVendorOrdersAddWorkOrder />}
							/>
							<Route
								path={URI.project.vendorOrder.editWorkOrder}
								element={<ProjectVendorOrdersAddWorkOrder />}
							/>
							<Route
								path={URI.project.vendorOrder.wOcomponentEdit}
								element={<ItemAddComponentAdd />}
							/>
							<Route
								path={URI.project.vendorOrder.addWorkOrderGeneratedWO}
								element={<ProjectVendorOrdersAddWorkOrderGeneratedWO />}
							/>
							<Route
								path={URI.project.vendorOrder.addWorkOrderEditGeneratedWO}
								element={<ProjectVendorOrdersAddWorkOrderEditGeneratedWO />}
							/>
							<Route
								path={URI.project.vendorOrder.modifyStatus}
								element={<ProjectVendorOrdersModifyStatus />}
							/>
							<Route
								path={URI.project.vendorOrder.editStatus}
								element={<ProjectVendorOrdersModifyStatusEdit />}
							/>

							{/* Items */}
							<Route path={URI.project.itemAdd} element={<ItemAdd />} />
							<Route path={URI.project.itemEdit} element={<ItemAdd />} />
							<Route path={URI.project.itemEditPage} element={<ItemAdd />} />
							<Route
								path={URI.project.item.componentAdd}
								element={<ItemAddComponentAdd />}
							/>
							<Route
								path={URI.project.item.componentAddPage}
								element={<ItemAddComponentAdd />}
							/>
							<Route
								path={URI.project.item.componentEdit}
								element={<ItemAddComponentAdd />}
							/>

							<Route path={URI.project.newItemEdit} element={<ItemsPage />} />
							<Route
								path={URI.project.newComponentEdit}
								element={<Component />}
							/>
							<Route
								path={URI.project.item.componentEditPage}
								element={<ItemAddComponentAdd />}
							/>
							<Route
								path={URI.project.item.componentEditOrderStatusPO}
								element={<ItemAddComponentEditOrderStatusPO />}
							/>
							<Route
								path={URI.project.item.componentEditOrderStatusInvoice}
								element={<ItemAddComponentEditOrderStatusInvoice />}
							/>
							<Route
								path={URI.project.itemsStore}
								element={<ProjectAddItemsStore />}
							/>
							<Route
								path={URI.project.item.transferItem}
								element={<ItemTransfer />}
							/>
							<Route
								path={URI.project.item.transferItemEdit}
								element={<ItemTransferEdit />}
							/>

							<Route
								path={URI.project.budgetAdd}
								element={<ProjectAddBudgetAdd />}
							/>
							<Route
								path={URI.project.budgetEdit}
								element={<ProjectAddBudgetAdd />}
							/>
						</Route>

						{/* Items */}
						<Route path={URI.item.base} element={<Item />}>
							<Route
								path={URI.item.clippedItem.base}
								element={
									<BudgetBannerProvider>
										<Clipper noCheck={true} />
									</BudgetBannerProvider>
								}
							/>
							<Route
								path={URI.item.clippedItemGrid}
								element={<ClipperGrid />}
							/>
							<Route path={URI.item.catalog.base} element={<Catalog />}>
								<Route path={URI.item.catalog.base} element={<CatalogList />} />
								<Route path={URI.item.catalog.add} element={<CatalogItem />} />
								<Route
									path={URI.item.catalog.edit}
									element={<CatalogItemEdit />}
								/>
								<Route
									path={URI.item.catalog.addComponent}
									element={<CatalogItemComponentAdd />}
								/>
								<Route
									path={URI.item.catalog.addComponentPage}
									element={<CatalogItemComponentAdd />}
								/>
								<Route
									path={URI.item.catalog.addComponentEditOrderStatusPO}
									element={<CatalogItemComponentEditOrderStatusPO />}
								/>
								<Route
									path={URI.item.catalog.addComponentEditOrderStatusInvoice}
									element={<CatalogItemComponentEditOrderStatusInvoice />}
								/>
							</Route>
							<Route path={URI.item.search} element={<ItemSearch />}>
								<Route path={URI.item.search} element={<ItemSearchList />} />
							</Route>
						</Route>

						<Route path={URI.stockItem.base} element={<StockItems />}>
							<Route path={URI.stockItem.list} element={<StockItemsList />} />
							<Route path={URI.stockItem.edit} element={<StockItemEdit />} />
						</Route>

						{/* Time Entry */}
						<Route path={URI.timeEntry.base}>
							<Route
								path={URI.timeEntry.base}
								element={
									<TimeEntryListView
										{...this.props}
										{...{
											viewModel: this.appContainer.timeEntryListViewModel,
											timeEntryAddEditModel:
												this.appContainer.timeEntryAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.timeEntry.add}
								element={
									<TimeEntryAddEditView
										{...this.props}
										{...{
											viewModel: this.appContainer.timeEntryAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.timeEntry.edit}
								element={
									<TimeEntryAddEditView
										{...this.props}
										{...{
											viewModel: this.appContainer.timeEntryAddEditViewModel,
										}}
									/>
								}
							/>
						</Route>

						{/* Contacts */}
						<Route path={URI.contact.base}>
							{/* Clients */}
							<Route
								path={URI.contact.client}
								element={
									<ClientListComponent
										{...this.props}
										{...{
											clientModel: this.appContainer.clientListViewModel,
											clientAddEditModel:
												this.appContainer.clientAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.clientAdd}
								element={
									<ClientAddEditComponent
										{...this.props}
										{...{
											clientAddEditModel:
												this.appContainer.clientAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.clientEdit}
								element={
									<ClientAddEditComponent
										{...this.props}
										{...{
											clientAddEditModel: new ClientAddEditViewModel(
												this.appContainer.clientService
											),
											addressAddEditModel: new AddressAddEditViewModel(
												this.appContainer.addressService
											),
										}}
									/>
								}
							/>

							{/* Employee */}
							<Route
								path={URI.contact.employee}
								element={
									<EmployeeListComponent
										{...this.props}
										{...{
											employeeAddEditViewModel:
												this.appContainer.employeeAddEditViewModel,
											employeeModel: this.appContainer.employeeListViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.employeeAdd}
								element={
									<EmployeeAddEditComponent
										{...this.props}
										{...{
											employeeAddEditModel:
												this.appContainer.employeeAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.employeeEdit}
								element={
									<EmployeeAddEditComponent
										{...this.props}
										{...{
											employeeAddEditModel:
												this.appContainer.employeeAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
										}}
									/>
								}
							/>

							{/* Vendor */}
							<Route
								path={URI.contact.vendor}
								element={
									<VendorListComponent
										{...this.props}
										{...{
											viewModel: this.appContainer.vendorListViewModel,
											vendorAddEditModel:
												this.appContainer.vendorAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.vendorAdd}
								element={
									<VendorAddEditComponent
										{...this.props}
										{...{
											vendorAddEditModel:
												this.appContainer.vendorAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
											payeeAddressAddEditModel:
												this.appContainer.payeeAddressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.vendorEdit}
								element={
									<VendorAddEditComponent
										{...this.props}
										{...{
											vendorAddEditModel:
												this.appContainer.vendorAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
											payeeAddressAddEditModel:
												this.appContainer.payeeAddressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.employeeAdd}
								element={
									<EmployeeAddEditComponent
										{...this.props}
										{...{
											employeeAddEditModel:
												this.appContainer.employeeAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.employeeEdit}
								element={
									<EmployeeAddEditComponent
										{...this.props}
										{...{
											employeeAddEditModel:
												this.appContainer.employeeAddEditViewModel,
											addressAddEditModel:
												this.appContainer.addressAddEditViewModel,
										}}
									/>
								}
							/>

							<Route
								path={URI.contact.employeeTimeActivityEdit}
								element={<EmployeeAddEditTimeActivity />}
							/>
						</Route>

						{/* Contacts */}
						<Route path={URI.contact.base} element={<Contacts />}>
							<Route
								path={URI.contact.employeeAddPage}
								element={<EmployeesAdd />}
							/>
						</Route>

						{/* Accounts Receiveable */}
						<Route
							path={URI.accountsReceivable.base}
							element={<AccountsReceivable />}
						>
							<Route path={URI.accountsReceivable.report} element={<Base />} />
							<Route
								path={URI.accountsReceivable.clientInvoices.base}
								element={<ClientInvoices />}
							>
								<Route
									path={URI.accountsReceivable.clientInvoices.base}
									element={<ClientInvoicesList />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.add}
									element={<ClientInvoiceAdd />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.edit}
									element={<ClientInvoiceAdd />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.addOverridePrice}
									element={<ClientInvoiceAddOverridePrice />}
								/>
								<Route
									path={
										URI.accountsReceivable.clientInvoices.existingInvoiceView
									}
									element={<ClientInvoiceExistingView />}
								/>
								<Route
									path={
										URI.accountsReceivable.clientInvoices
											.existingInvoiceAdjustment
									}
									element={<ClientInvoiceExistingInvoiceAdjust />}
								/>
								<Route
									path={
										URI.accountsReceivable.clientInvoices
											.existingInvoiceAdjustmentAdd
									}
									element={<ClientInvoiceExistingInvoiceAdjustAdd />}
								/>
								<Route
									path={
										URI.accountsReceivable.clientInvoices
											.existingreverseCreditInvoice
									}
									element={<ClientInvoiceExistingReverseCreditInvoice />}
								/>
								<Route
									path={
										URI.accountsReceivable.clientInvoices
											.existingHighlightedInvoice
									}
									element={<ClientInvoiceExistingUpdatedHighlightedInvoice />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.listNew}
									element={<ClientInvoicesList page={'new'} />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.listExisting}
									element={<ClientInvoicesList page={'existing'} />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.list}
									element={<ClientInvoicesList />}
								/>
								<Route
									path={URI.accountsReceivable.clientInvoices.financeCharges}
									element={<ClientInvoiceFinanceCharge />}
								/>
							</Route>

							<Route
								path={URI.accountsReceivable.cashReceipt.base}
								element={<CashReceipt />}
							>
								<Route
									path={URI.accountsReceivable.cashReceipt.base}
									element={<CashReceiptList />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.existingReceiptView}
									element={<CashReceiptExistingView />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.add}
									element={<CashReceiptAdd />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.edit}
									element={<CashReceiptAdd />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.addMisc}
									element={<CashReceiptAddMisc />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.editMisc}
									element={<CashReceiptAddMisc />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.listNew}
									element={<CashReceiptList page={'new'} />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.listExisting}
									element={<CashReceiptList page={'existing'} />}
								/>
								<Route
									path={URI.accountsReceivable.cashReceipt.listBankDeposit}
									element={<CashReceiptList page={'bank-deposit'} />}
								/>
							</Route>

							<Route
								path={URI.accountsReceivable.returnsAndCredit.base}
								element={<ReturnsAndCredits />}
							>
								<Route
									path={URI.accountsReceivable.returnsAndCredit.base}
									element={<ReturnsAndCreditsList />}
								/>
							</Route>
							<Route
								path={URI.accountsReceivable.designPay.base}
								element={<DesignPay />}
							/>
						</Route>

						{/* Accounts Payable */}
						<Route
							path={URI.accountsPayable.base}
							element={<AccountsPayable />}
						>
							<Route path={URI.accountsPayable.report} element={<Base />} />
							<Route
								path={URI.accountsPayable.comingSoon}
								element={<ComingSoon />}
							/>

							<Route
								path={URI.accountsPayable.vendorInvoice.base}
								element={<VendorInvoice />}
							>
								<Route
									path={URI.accountsPayable.vendorInvoice.base}
									element={<VendorInvoiceList />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.add}
									element={<VendorInvoiceAdd />}
								/>

								<Route
									path={URI.accountsPayable.vendorInvoice.editInvoice}
									element={<VendorInvoiceAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editNewInvoice}
									element={<VendorInvoiceAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.addDeposit}
									element={<VendorDepositAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editDeposit}
									element={<VendorDepositAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editNewDeposit}
									element={<VendorDepositAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.addRec}
									element={<VendorInvoiceAddRecurring />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editRec}
									element={<VendorInvoiceEditdExpense />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.addQuickDeposit}
									element={<VendorInvoiceAddDeposit />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.addMisc}
									element={<VendorMiscAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editMisc}
									element={<VendorMiscAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.editNewMisc}
									element={<VendorMiscAdd />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.list}
									element={<VendorInvoiceList />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.list}
									element={<ClientInvoiceFinanceCharge />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.listNew}
									element={<VendorInvoiceList page={'new'} />}
								/>
								<Route
									path={URI.accountsPayable.vendorInvoice.listExisting}
									element={<VendorInvoiceList page={'existing'} />}
								/>
							</Route>

							<Route
								path={URI.accountsPayable.payment.base}
								element={<Payment />}
							>
								<Route
									path={URI.accountsPayable.payment.base}
									element={<PaymentList />}
								/>
								<Route
									path={URI.accountsPayable.payment.list}
									element={<PaymentList />}
								/>
								<Route
									path={URI.accountsPayable.payment.formModifyAmount}
									element={<PaymentFormModifyAmount />}
								/>
								<Route
									path={URI.accountsPayable.payment.formCreditCardPayment}
									element={<PaymentFormCreditCardPayment />}
								/>
								<Route
									path={URI.accountsPayable.payment.formOnlinePayment}
									element={<PaymentFormOnlinePayment />}
								/>
								<Route
									path={URI.accountsPayable.payment.formCheckWirePayment}
									element={<PaymentFormCheckWirePayment />}
								/>
							</Route>

							<Route
								path={URI.accountsPayable.checkbook.base}
								element={<Checkbook />}
							>
								<Route
									path={URI.accountsPayable.checkbook.base}
									element={<CheckbookList />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.list}
									element={<CheckbookList />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.reconcile}
									element={<Reconcile />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.journalEntryDetail}
									element={<CheckbookJournalEntryDetail />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.checkDetail}
									element={<CheckbookCheckDetail />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.depositDetail}
									element={<CheckbookDepositDetail />}
								/>
								<Route
									path={URI.accountsPayable.checkbook.history}
									element={<CheckbookHistory />}
								/>
							</Route>

							<Route
								path={URI.accountsPayable.creditCard.base}
								element={<CreditCard />}
							>
								<Route
									path={URI.accountsPayable.creditCard.base}
									element={<CreditCardList />}
								/>
								<Route
									path={URI.accountsPayable.creditCard.list}
									element={<CreditCardList />}
								/>
								<Route
									path={URI.accountsPayable.creditCard.reconcile}
									element={
										<ReconcileProvider>
											<CreditCardReconcile />
										</ReconcileProvider>
									}
								/>
								<Route
									path={URI.accountsPayable.creditCard.history}
									element={<CreditCardReconcileHistory />}
								/>
							</Route>

							<Route
								path={URI.accountsPayable.vendorCredit.base}
								element={<VendorCredit />}
							>
								<Route
									path={URI.accountsPayable.vendorCredit.base}
									element={<VendorCreditList />}
								/>
								<Route
									path={URI.accountsPayable.vendorCredit.list}
									element={<VendorCreditList />}
								/>
							</Route>
						</Route>

						{/* General Ledger */}
						<Route path={URI.generalLedger.base} element={<GeneralLedger />}>
							<Route path={URI.generalLedger.report} element={<Base />} />
							<Route
								path={URI.generalLedger.account.base}
								element={<Account />}
							>
								<Route
									path={URI.generalLedger.account.base}
									element={<AccountList />}
								/>
								<Route
									path={URI.generalLedger.account.add}
									element={<AccountAdd />}
								/>
								<Route
									path={URI.generalLedger.account.edit}
									element={<AccountAdd />}
								/>
								<Route
									path={URI.generalLedger.account.list}
									element={<AccountList />}
								/>
							</Route>
							<Route
								path={URI.generalLedger.journalEntry.base}
								element={<JournalEntry />}
							>
								<Route
									path={URI.generalLedger.journalEntry.add}
									element={<JournalEntryAdd />}
								/>
								<Route
									path={URI.generalLedger.journalEntry.edit}
									element={<JournalEntryEdit />}
								/>
								<Route
									path={URI.generalLedger.journalEntry.listNew}
									element={<JournalEntryList page={'new'} />}
								/>
								<Route
									path={URI.generalLedger.journalEntry.listExisting}
									element={<JournalEntryList page={'existing'} />}
								/>
								<Route
									path={URI.generalLedger.journalEntry.recurringJournalEntry}
									element={<JournalEntryRecurring />}
								/>
								<Route
									path={URI.generalLedger.journalEntry.recurringJournalEntryAdd}
									element={<JournalEntryRecurringAdd />}
								/>
							</Route>
							<Route
								path={URI.generalLedger.transactionSearch.base}
								element={<TransactionSearch />}
							>
								<Route
									path={URI.generalLedger.transactionSearch.list}
									element={<TransactionSearchList />}
								/>
							</Route>
						</Route>

						{/* Time Management */}
						<Route
							path={URI.timeManagement.base}
							element={<TimeManagement title="Time Management" />}
						>
							<Route
								path={URI.timeManagement.appointment}
								element={<TaskAppointment title="Task Appointment" />}
							/>
							<Route
								path={URI.timeManagement.appointmentAdd}
								element={<TaskAppointmentAdd />}
							/>
							<Route
								path={URI.timeManagement.appointmentToDoAdd}
								element={<TaskAppointmentToDoAdd />}
							/>
							<Route
								path={URI.timeManagement.appointment}
								element={<Base title="Task Review" />}
							/>
							<Route path="calendar" element={<Base title="Calendar" />} />
						</Route>

						{/* Review & Send */}
						<Route path={URI.reviewSend.base} element={<ReviewSend />}>
							<Route
								path={URI.reviewSend.base}
								element={<ReviewSendList title="Review & Send" />}
							/>
							<Route
								path={URI.reviewSend.add}
								element={<Base title="Add Review & Send" />}
							/>
						</Route>

						<Route path={URI.report.base} element={<Reporting />} />
						<Route
							path={URI.glossary.base}
							element={<Base title="Glossaries" />}
						/>

						{/* Glossary */}
						<Route path={URI.glossary.base} element={<Glossary />}>
							<Route path={URI.glossary.base} element={<GlossaryList />} />
							<Route path={URI.glossary.list} element={<GlossaryList />} />
							<Route
								path={URI.glossary.add.locations}
								element={<GlossaryAddLocations />}
							/>
							<Route
								path={URI.glossary.add.salesCategory}
								element={<GlossaryAddSalesCategory />}
							/>
							<Route
								path={URI.glossary.add.itemGroups}
								element={<GlossaryAddItemGroups />}
							/>
							<Route
								path={URI.glossary.add.shipToCodes}
								element={<GlossaryAddShipToCodes />}
							/>
							<Route
								path={URI.glossary.add.remarks}
								element={<GlossaryAddRemarks />}
							/>
							<Route
								path={URI.glossary.add.specificationTemplates}
								element={<GlossaryAddSpecificationTemplates />}
							/>
							<Route
								path={URI.glossary.add.timeActivities}
								element={<GlossaryAddTimeActivities />}
							/>
							<Route
								path={URI.glossary.add.salesTaxCodes}
								element={<GlossaryAddSalesTaxCodes />}
							/>
							<Route
								path={URI.glossary.add.salesTaxCalculation}
								element={<GlossaryAddSalesTaxCalculation />}
							/>
							<Route
								path={URI.glossary.edit.salesTaxCalculation}
								element={<GlossaryEditSalesTaxCalculation />}
							/>
							<Route
								path={URI.glossary.add.unitsOfMeasure}
								element={<GlossaryAddUnitsOfMeasure />}
							/>

							<Route
								path={URI.glossary.edit.itemGroups}
								element={<GlossaryAddItemGroups />}
							/>
							<Route
								path={URI.glossary.edit.salesTaxCodes}
								element={<GlossaryAddSalesTaxCodes />}
							/>
							<Route
								path={URI.glossary.edit.locations}
								element={<GlossaryAddLocations />}
							/>
							<Route
								path={URI.glossary.edit.timeActivities}
								element={<GlossaryAddTimeActivities />}
							/>
							<Route
								path={URI.glossary.edit.salesCategory}
								element={<GlossaryAddSalesCategory />}
							/>
							<Route
								path={URI.glossary.edit.specificationTemplates}
								element={<GlossaryAddSpecificationTemplates />}
							/>
							<Route
								path={URI.glossary.edit.remarks}
								element={<GlossaryAddRemarks />}
							/>
							<Route
								path={URI.glossary.edit.shipToCodes}
								element={<GlossaryAddShipToCodes />}
							/>
							<Route
								path={URI.glossary.edit.unitsOfMeasure}
								element={<GlossaryAddUnitsOfMeasure />}
							/>
						</Route>

						{/* Account Settings */}
						<Route
							path={URI.accountSettings.base}
							element={
								<RouteGuard>
									<AccountSettings title="Account Settings" />
								</RouteGuard>
							}
						/>

						{/* Settings */}
						<Route path={URI.settings.base} element={<Settings />}>
							<Route path={URI.settings.list} element={<SettingsList />} />
							<Route path={URI.settings.userManagement.list} element={<User />}>
								<Route
									path={URI.settings.userManagement.list}
									element={<UserList />}
								/>
								<Route
									path={URI.settings.userManagement.add}
									element={<UserAdd />}
								/>
								<Route
									path={URI.settings.userManagement.edit}
									element={<UserEdit />}
								/>
							</Route>
							<Route
								path={URI.settings.userSettings.list}
								element={<UserSettings />}
							>
								<Route
									path={URI.settings.userSettings.list}
									element={<UserSettingsList />}
								/>
							</Route>
							<Route
								path={URI.settings.paymentSettings.list}
								element={
									<RouteGuard
										attributeNo={224}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
									>
										<PaymentSettings />
									</RouteGuard>
								}
							>
								<Route
									path={URI.settings.paymentSettings.list}
									element={
										<RouteGuard
											attributeNo={224}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
										>
											<PaymentListSettings />
										</RouteGuard>
									}
								/>
								<Route
									path={URI.settings.paymentSettings.connected}
									element={
										<RouteGuard
											attributeNo={224}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
										>
											<PaymentListSettings />
										</RouteGuard>
									}
								/>
							</Route>
							{/* Hook Forms */}

							<Route
								path="/settings/company"
								element={
									<RouteGuard>
										<Company />
									</RouteGuard>
								}
							/>
							<Route
								path={URI.settings.workflowSettings.base}
								element={
									<RouteGuard>
										<WorkflowSettings />
									</RouteGuard>
								}
							>
								<Route
									path={URI.settings.workflowSettings.general}
									element={<WorkflowSettingsMain page={'general'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.print}
									element={<WorkflowSettingsMain page={'print'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.time}
									element={<WorkflowSettingsMain page={'time'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.proposals}
									element={<WorkflowSettingsMain page={'proposals'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.invoices}
									element={<WorkflowSettingsMain page={'invoices'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.powoorders}
									element={<WorkflowSettingsMain page={'powoorders'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.schedule}
									element={<WorkflowSettingsMain page={'schedule'} />}
								/>
								<Route
									path={URI.settings.workflowSettings.budget}
									element={<WorkflowSettingsMain page={'budget'} />}
								/>
							</Route>
						</Route>

						<Route
							path={URI.settings.base}
							element={<Base title="Settings" />}
						/>
						{/* Dashboard */}
						<Route path={URI.dashboard.base} element={<Dashboard />} />
					</Route>

					{/* UI Kit Layout */}
					<Route path={URI.uikit.base} element={<UiKitLayout />}>
						<Route path={URI.uikit.base} element={<UiKitHome />} />
						<Route path={URI.uikit.component} element={<UiKitComponents />} />
						<Route path={URI.uikit.typography} element={<Typography />} />
						<Route path={URI.uikit.icon} element={<Icons />} />
						<Route path={URI.uikit.colour} element={<Colours />} />
					</Route>

					{/* Login Layout */}
					<Route path={URI.login.base} element={<LoginLayout />}>
						<Route path={URI.login.base} element={<Login />} />
						<Route path={URI.login.password} element={<ForgotPassword />} />
					</Route>

					{/* Accept Invitation Layout */}
					<Route
						path={URI.acceptInvitation.base}
						element={<AcceptInvitation />}
					>
						<Route
							path={URI.acceptInvitation.base}
							element={<AcceptInvitation />}
						/>
					</Route>

					{/* Reset Password Layout */}
					<Route path={URI.resetPassword.base} element={<ResetPassword />}>
						<Route path={URI.resetPassword.base} element={<ResetPassword />} />
					</Route>
					<Route
						index
						path={URI.invalidInstance}
						element={<InvalidInstanceComponent />}
					/>
					<Route path="/" element={<AppLayout />}>
						<Route path="*" element={<NotFoundPage />} />
					</Route>
				</Routes>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		);
	}
}

export default AppNavigation;

function RequireAuth({ children }: { children: JSX.Element }) {
	const auth = isLoggedIn();
	const location = useLocation();

	if (!auth) {
		window.localStorage.setItem('location', location.pathname);
		return <Navigate to="/login" />;
	}

	return children;
}
