import React, { useRef, useState, useEffect, Fragment } from 'react';
import Icon from 'legacy/app/components/Icon/Icon';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import RecentProjectsIcon from '../../../assets/images/icons/Recent_Projects-Icon.svg';
import RecentProjectCard from '../RecentProjectCard/RecentProjectCard';
import useGetRecentProjects from 'legacy/lib/api/hooks/useGetRecentProjects';
import ArrowLeftcon from '../../../assets/images/icons/arrow-icon-left.svg';
import ArrowRightIcon from '../../../assets/images/icons/arrow-icon-right.svg';
import { DashboardUrls } from 'legacy/app/constants/dashboard/dashboardUrls';
import PlaceholderCard from '../RecentProjectCard/PlaceholderCard';
import EmptyRecentProjects from './EmptyRecentProjects';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'legacy/app/context/session';

const RecentProjects = () => {
	const MAX_PROJECTS = 5;
	const { usercode } = useSession();
	const filter = `?$filter=isClosed eq false and userCode eq ${usercode}&$top=${MAX_PROJECTS}&$orderby=lastactivity desc`;
	const { projects } = useGetRecentProjects(filter, true);
	const [hasOverflow, setHasOverflow] = useState(false);
	const navigate = useNavigate();

	const checkOverflow = () => {
		const container = scrollContainerRef.current;
		if (container) {
			const hasOverflow = container.scrollWidth > container.clientWidth;
			setHasOverflow(hasOverflow);
		}
	};

	useEffect(() => {
		checkOverflow();
		window.addEventListener('resize', checkOverflow);
		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, [projects]);

	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const handleScroll = (direction: string) => {
		if (scrollContainerRef.current) {
			scrollContainerRef.current.scrollBy({
				left: direction === 'right' ? 300 : -300,
				behavior: 'smooth',
			});
		}
	};

	const handleDrag = (e: React.TouchEvent<HTMLDivElement>) => {
		let startX = e.touches[0].clientX;
		const handleTouchMove = (moveEvent: TouchEvent) => {
			if (scrollContainerRef.current) {
				const moveX = moveEvent.touches[0].clientX;
				const difference = startX - moveX;
				scrollContainerRef.current.scrollLeft += difference;
				startX = moveX;
			}
		};
		const handleTouchEnd = () => {
			document.removeEventListener('touchmove', handleTouchMove);
			document.removeEventListener('touchend', handleTouchEnd);
		};
		document.addEventListener('touchmove', handleTouchMove);
		document.addEventListener('touchend', handleTouchEnd);
	};

	return (
		<div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md tw-overflow-hidden">
			{projects?.length > 0 ? (
				<Fragment>
					<div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
						<div className="tw-App tw-flex tw-items-center">
							<Icon path={RecentProjectsIcon} alt="recent-projects-icon" />
							<h1 className="tw-text-2xl md:tw-text-2xl tw-ml-1.5">
								Recent Projects
							</h1>
						</div>
						<div className="tw-flex tw-flex-col sm:tw-flex-row">
							<BaseButton
								text="+ Add"
								onClick={() => navigate(DashboardUrls.addUrl)}
								buttonClass="tw-px-4 tw-py-2 tw-border tw-border-[#006a53] tw-text-black tw-rounded tw-transition hover:tw-cursor-pointer tw-text-center tw-mr-0 tw-mb-2 sm:tw-mb-0 sm:tw-mr-4 tw-text-nowrap"
							/>
							<BaseButton
								text="View all"
								onClick={() => navigate(DashboardUrls.viewAll)}
								buttonClass="tw-px-4 tw-py-2 tw-border tw-border-[#006a53] tw-text-black tw-rounded tw-transition hover:tw-cursor-pointer tw-text-center"
							/>
						</div>
					</div>
					<div className="tw-relative tw-w-full tw-flex lg:tw-justify-center">
						{hasOverflow && (
							<button
								onClick={() => handleScroll('left')}
								className="tw-hidden md:tw-block tw-transform tw--translate-y-1/2 tw-bg-white tw-rounded-full tw-self-center tw-z-10 tw-p-2"
							>
								<Icon
									path={ArrowLeftcon}
									alt="arrow-left"
									iconClass="tw-max-w-none"
								/>
							</button>
						)}
						<div
							className={`tw-flex tw-overflow-scroll tw-scrollbar-hide xl:tw-w-[90%] ${
								!hasOverflow ? 'xl:tw-justify-center' : ''
							}`}
							ref={scrollContainerRef}
							onTouchStart={handleDrag}
						>
							{projects?.map((project, index) => (
								<RecentProjectCard
									key={`project-card-item-${index}`}
									amountReceived={project.totalPaymentsInFromClient}
									budget={project.budgetTotal}
									clientName={project.clientName}
									date={project.dateOpened}
									primaryImages={project?.primaryImages || []}
									projectName={project.projectName}
									totalAmount={project.totalEstimatedPriceToClient}
									projectId={project?.id}
									projectCode={project.proj}
								/>
							))}
							{projects?.length < MAX_PROJECTS && <PlaceholderCard />}
						</div>
						{hasOverflow && (
							<button
								onClick={() => handleScroll('right')}
								className="tw-hidden md:tw-block tw-transform tw--translate-y-1/2 tw-bg-white tw-rounded-full tw-self-center tw-z-10 tw-p-2"
							>
								<Icon
									path={ArrowRightIcon}
									alt="arrow-right"
									iconClass="tw-max-w-none md:tw-max-w-none"
								/>
							</button>
						)}
					</div>
				</Fragment>
			) : (
				<EmptyRecentProjects />
			)}
		</div>
	);
};

RecentProjects.displayName = 'RecentProjects';

export default RecentProjects;
