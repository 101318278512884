export enum FeatureFlags {
	RAINFOREST = 'RAINFOREST',
	AddInventoryItem = 'ADD_INVENTORY_ITEM',
	AddInventoryItemComponent = 'ADD_INVENTORY_ITEM_COMPONENT',
	CheckbookReconcile = 'CHECKBOOK_RECONCILE',
	ProjectProposalStatus = 'PROJECT_PROPOSAL_STATUS',
	SpecificationTemplates = 'SPECIFICATION_TEMPLATES',
	ProjectSchedule = 'PROJECT_SCHEDULE',
	Reporting = 'REPORTING',
	MultipleImages = 'MULTIPLE_IMAGES',
	BudgetInputs = 'BUDGET_INPUTS',
	FinanceCharges = 'FINANCE_CHARGES',
	EnableReorder = 'ENABLE_REORDER',
}
