import React from 'react';
import { Container } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { getSubComponent } from '../../../../helpers/Util';
import { ApiService } from '../../../../lib/api/HttpService';
import Documents from '../../documents/Documents';
import ItemAddComponentAddOrderStatus from '../item-add/ItemAddComponentAddOrderStatus';
import { displayAlertError } from 'legacy/utilities/Response';
import Spinner from 'legacy/app/components/help/Spinner';
import ItemAddComponentAddSpecification from '../item-add/ItemAddComponentAddSpecification';

class ItemAddComponentAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.api = new ApiService();
		this.state = {
			newCompId: '',
			loading: false,
			data: {},
			projectName: '',
			error: null,
			project: [],
			associatedComponents: [],
			removedAssocIds: [],
			isCancel: false,
		};

		this.compTypePTypeMap = [
			{
				markup: 'markupmerc',
				disc: 'usediscountmerc',
				tax: 'taxm',
				key: 'm',
			},
			{
				markup: 'markupdesign',
				disc: 'usediscountdesign',
				tax: 'taxd',
				key: 'd',
			},
			{
				markup: 'markupfreight',
				disc: 'usediscountfreight',
				tax: 'taxf',
				key: 'f',
			},
			{
				markup: 'markupinstall',
				disc: 'usediscountinstall',
				tax: 'taxi',
				key: 'i',
			},
			{
				markup: 'markuplabor',
				disc: 'usediscountlabor',
				tax: 'taxl',
				key: 'l',
			},
			{
				markup: 'markupother',
				disc: 'usediscountother',
				tax: 'taxo',
				key: 'o',
			},
		];
	}

	componentInit() {
		this.updatedData = this.props.updatedData;
		this.projectId = this.props.params.id;
		this.itemId = this.props.params.itemId;
		this.id = this.props.params.componentId;
	}

	async componentDidMount() {
		try {
			this.setState({
				loading: true,
			});
			await this.fetchComponentIfNeeded();
			this.setIsLoaded(true);
			this.setState({
				loading: false,
			});
		} catch {
			this.setState({
				loading: false,
				error: true,
			});
			displayAlertError(
				'There was an error getting all the information needed, please try again'
			);
		}
	}

	async fetchComponentIfNeeded() {
		const projects = await this.api.getProjects(
			`?$filter=id eq ${this.projectId}`
		);
		this.setState({
			projectName: `${projects[0].projn} [${projects[0].proj}]`,
			projects,
			data: {
				...this.state.data,
				...{
					component: this.props.component,
					project: projects[0],
					markupmerc: projects[0].markupmerc,
					markupdesign: projects[0].markupdesign,
					markupfreight: projects[0].markupfreight,
					markupinstall: projects[0].markupinstall,
					markuplabor: projects[0].markuplabor,
					markupother: projects[0].markupother,
					usediscountmerc: projects[0].usediscountmerc,
					usediscountdesign: projects[0].usediscountdesign,
					usediscountfreight: projects[0].usediscountfreight,
					usediscountinstall: projects[0].usediscountinstall,
					usediscountlabor: projects[0].usediscountlabor,
					usediscountother: projects[0].usediscountother,
					sidemark: projects[0].posidemark,
				},
			},
		});
		const company = await this.api.get('company');
		this.compTypePTypeMap = this.compTypePTypeMap.map((i) => ({
			...i,
			desc: company[`compview${i.key}`],
		}));

		let data;

		let items = this.props.subcomps;

		data = items[0] ?? {};
		if (data.usedisc === 1) {
			data.estcost = data.feecost;
		}

		this.setIsLoaded(true);
		this.renderData(data);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: {
				...this.state.data,
				...(data ?? {}),
			},
		});
	}

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
		this.props.onChildrenDataChange(this.updatedData);
	};

	getContent() {
		const { data } = this.state;
		const { activeMenu } = this.props;

		return getSubComponent(activeMenu, {
			'special instructions': (
				<ItemAddComponentAddSpecification
					data={data.component?.[0]}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			documents: (
				<Documents
					objectType="Component"
					objectId={this.id}
					onRefresh={(numAttachments) => {
						this.props.setNumberOfFiles(numAttachments);
					}}
				/>
			),
		});
	}

	render() {
		const inactiveTabs = ['info', 'order status'];
		const isSelectedTabValid = !inactiveTabs.includes(this.props.activeMenu);
		if (this.state.loading && isSelectedTabValid) {
			return <Spinner isChild />;
		}
		if (this.state.error) {
			return null;
		}

		return isSelectedTabValid
			? this.renderView(
					<div className="content-padding min-height has-action-bar">
						<Container fluid>{this.getContent()}</Container>
					</div>
			  )
			: null;
	}
}

export default WithRouter(ItemAddComponentAdd);
